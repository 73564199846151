<template>
  <div class="voluntaryform" style="width: 80%; margin: 0 auto;">

    <div class="voluntaryTitle">
      <p class="iconToast">
        <span> ! </span>
        重要提示：
      </p>
      <p class="columnToast">
        注：以下志愿表为模拟填报志愿表，正式填报请登录河北省考试院指定网站
      </p>

      <p class="columnToast">
        申明：参考数据来自于学校网站及教育部所属阳光高考网、考试院等官方网站，填报过程中如有数据问题则以省考试院发布的招生计划为准。
      </p>
    </div>
    <el-tabs type="border-card" v-model="index" @tab-click="tabclick">
      <div class="top">
        <div>
          <!-- <h1>志愿表：{{ SupportTable }}</h1> -->
          <h2>我的成绩：{{ $store.state.userInfo.score }}/{{ $store.state.userInfo.subject }}</h2>
        </div>
        <el-button v-show="index == 0" style="margin: 0 10px 0 auto; height: 32px; line-height: 7px" type="primary"
          @click="index == 0 ? $router.push('/fillingZZ') : $router.push('/fillingZN')">添加志愿</el-button>
        <div @click="downloadvolunteer" v-show="index == 0">
          <i class="el-icon-download"></i>
          <span>导出</span>
        </div>
      </div>

      <!-- <el-tab-pane label="自主填报" name='0'></el-tab-pane>
      <el-tab-pane label='智能填报' name="1"></el-tab-pane> -->
      <div class="tableCss" v-loading="loading">
        <!-- 表格区域 -->
        <el-table :data="tableData" style="border-radius: 10px;border:1px solid #FAFAFA" :header-cell-style="{
      backgroundColor: '#FAFAFA',
      color: 'black',
      fontSize: '17px',
      textAlign: 'center',

    }" :cell-style="{
      textAlign: 'center',

    }">>
          <el-table-column width="130" align="center" label="志愿序号">
            <template slot-scope="scope">
              志愿 {{ scope.row.volunteerNumber }}
            </template>
          </el-table-column>

          <el-table-column prop="" label="院校信息" width="220">
            <template slot-scope="scope">
              <p class="schoolName" @click="goCheckSchool(scope.row.scorePlansEntity)">
                {{ scope.row.scorePlansEntity.collegeName }}
                <el-popover placement="top-start" title="名企招聘" width="200" trigger="hover"
                  :content="scope.row.collegeEntity.famousRecruit">
                  <img slot="reference" v-if="userType == '05'" style="width: 20px;vertical-align: text-top;"
                    src="@/assets/imgs/pin.png" alt="">
                </el-popover>
              </p>
              <p class="f13 txagn">
                院校代号：{{ scope.row.scorePlansEntity.collegeCode || '--' }}
              </p>
              <p class="f13 txagn">{{ scope.row.scorePlansEntity.collegeProvince }} | {{
      scope.row.collegeEntity.collegeNature }}</p>

              <!-- <p class="f13 txagn">软科排名：{{ scope.row.bcuRanking || '--' }}</p> -->
              <p class="f13 txagn">
                {{ scope.row.scorePlansEntity.collegeLabel }}
              </p>
              <p class="f13 txagn">{{ scope.row.collegeEntity.collegeGenre }}</p>
              <p class="f13 txagn">硕/博点：{{ scope.row.collegeEntity.degreePrograms }} / {{
      scope.row.collegeEntity.doctoralPrograms }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="" label="学科评估" width="100">
            <template slot-scope="scope">

              <p
                v-if="scope.row.scorePlansEntity.grade == '_' || !scope.row.scorePlansEntity.grade || scope.row.scorePlansEntity.grade == '-'">
                ---
              </p>
              <p class="grade" v-else :style="{ backgroundColor: getGradeCss(scope.row.scorePlansEntity.grade) }">{{
      scope.row.scorePlansEntity.grade }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="" label="专业信息">
            <template slot-scope="scope">
              <span class="schoolPlan" v-if="scope.row.scorePlansEntity.majorname.length < 30">{{
      scope.row.scorePlansEntity.majorname }}</span>
              <el-popover v-else placement="top" width="370" trigger="hover" popper-class="popoverBackB">
                <div>{{ scope.row.scorePlansEntity.majorname }}</div>
                <span class="schoolPlan" slot="reference">{{ scope.row.scorePlansEntity.majorname.length >= 30 ?
      scope.row.scorePlansEntity.majorname.substr(0, 30) + '...' : scope.row.scorePlansEntity.majorname
                  }}</span>
              </el-popover>
              <p class="f13">专业代码:{{ scope.row.scorePlansEntity.majorCode || '--' }}</p>
              <p class="f13">
                <span style="display: inline">{{ scope.row.scorePlansEntity.particularYear }}招生人数:&nbsp;<span
                    style="display: inline; color: #30C9B2">{{ scope.row.scorePlansEntity.recruitStudentNumber
                    }}人</span>&nbsp;&nbsp;学费:&nbsp;{{
      scope.row.scorePlansEntity.tuition
    }}&nbsp;&nbsp;学制:&nbsp;{{ scope.row.scorePlansEntity.professionalSystem }}</span>
              </p>
              <p class="f13">
                <span style="display: inline"> {{ scope.row.scorePlansEntity.particularYear }}最低分/排名:&nbsp;{{
      scope.row.scorePlansEntity.score }}/{{
      scope.row.scorePlansEntity.ranking
    }}&nbsp;&nbsp;科目:&nbsp;</span>
                <span style="display: inline; color: #30C9B2">{{
        scope.row.scorePlansEntity.subjects
      }}</span> <span style="display: inline">选科要求:&nbsp;</span>
                <span style="display: inline; color: #30C9B2">{{ scope.row.scorePlansEntity.checkRequest }} </span>
                <!-- <span style="display:inline;font-weight: 900;font-size:9px">(以最新版招生计划为准，务必自行查阅)</span> -->
              </p>
              <p v-if="userType == '05'">
                <span class="sbText" v-if="scope.row.scorePlansEntity.ssd == '有'" style="background: #6cd7a1">硕</span>
                <span class="sbText" v-if="scope.row.scorePlansEntity.bsd == '有'" style="background: #99b2f1">博</span>
              </p>
            </template>
          </el-table-column>

          <el-table-column prop="" label="操作" width="200">
            <template slot-scope="scope">
              <el-popover placement="bottom" width="190" trigger="click" :ref="`popoverZN-${scope.$index}`"
                @hide="popoverHide(scope.row, scope.$index)">
                <div class="editVolun">
                  <span class="text">第</span>
                  <el-input-number v-model="scope.row.volunteerNumber" max="96" :controls="false" :max="96">
                  </el-input-number><span class="text">志愿</span>
                  <el-button @click="handClick(scope.row, scope.$index)">确定</el-button>
                </div>
                <div slot="reference" class="leadershipBtn" @click="scope.row.changeRank = false">
                  换位
                </div>
              </el-popover>
              <i v-if="scope.row.isempty" class="el-icon-delete" style="font-size: 18px; cursor: pointer"
                @click="del(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <pagination v-show="queryParams.total > 0" :total="queryParams.total" :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize" @pagination="onload()" />
    </el-tabs>
  </div>
</template>

<script>
import { queryDetailList } from '@/api/user'

import { resourceFileName, REQvolunteerList, REQvolunteerDel, REQvolunteercheckspace, REQvolunteerListZN, REQvolunteerDelZN, REQvolunteercheckspaceZN, REQdownloadvolunteer } from '@/api/aspiration.js'
export default {
  name: 'voluntaryform',
  components: {},
  props: {},
  data() {
    return {

      SupportTable: '2022-12-20',
      myGrade: '本科576分 物理/化学/生物',
      tableData: [],
      queryParams: {
        total: 1,
        pageNum: 1,
        pageSize: 10,
        userId: ''
      },
      overNumber: '',
      overNumberZN: '',
      loading: true,
      index: '1'
    }
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType;
    },
  },
  watch: {},
  created() {
    if (this.$store.state.userInfo.phonenumber) {
      this.queryParams.userId = this.$store.state.userInfo.phonenumber
      this.queryParams.userId = this.$store.state.userInfo.phonenumber
    } else {
      this.queryParams.userId = localStorage.getItem('phonenumber')
      this.queryParams.userId = localStorage.getItem('phonenumber')
    }
    this.index = this.$route.query.zzorzn + ''
    this.onload()
  },
  mounted() { },
  methods: {
    // 跳转查学校
    goCheckSchool(item) {
      this.$router.push({
        path: '/profile',
        query: {
          collegeNumber: item.collegeNumber
        }
      })
    },
    // 导出
    downloadvolunteer() {
      if (this.tableData.length == 0) {
        return this.$message.warning('请填报志愿后进行导出！')
      }
      let href = ''
      if (this.index == 0) {
        href = '/system/volunteer/download?userId='
      } else if (this.index == 1) {
        href = '/system/volunteer/intelligent/download?userId='
      }
      REQdownloadvolunteer(href + this.queryParams.userId).then(res => {
        window.location.href = process.env.VUE_APP_BASE_API + '/' + res.data.downUrl
      })
    },
    getGradeCss(grade) {
      if (grade.includes('A')) {
        return '#30C9B2'
      } else if (grade.includes('B')) {
        return '#3684E5'
      } else if (grade.includes('C')) {
        return '#FFB422'
      }
    },
    tabclick() {
      this.queryParams.pageSize = 10
      this.queryParams.pageNum = 1
      this.onload()
    },
    onload() {
      var self = this
      self.loading = true
      if (this.index == '0') {
        REQvolunteerList(this.queryParams).then(res => {
          if (res.code == 200) {
            this.commonResult(res)
          }
        })
      } else {
        // 如果智能填报没有id，不请求
        if (!this.$route.query.id) {
          self.$message.warning('请先选择智能志愿表')
          self.loading = false
          return
        }
        this.queryParams.id = this.$route.query.id;
        queryDetailList(this.queryParams).then(res => {
          this.commonResult(res)
        })
      }
    },
    commonResult(res) {

      const self = this
      // self.overNumber = res.data.overNumber
      self.queryParams.total = res.data.dataTable.total

      res.data.dataTable.rows.forEach(item => {
        if (item.scorePlansEntity.collegeLabel) {
          if (item.scorePlansEntity.collegeLabel == 0) {
            item.scorePlansEntity.collegeLabel = '---';
          } else {
            // collegeLabel是个字符串，有可能包含逗号（，）有可能包含顿号（、）
            // 所以写了这个判断
            if (item.scorePlansEntity.collegeLabel.indexOf(",") != -1) {
              item.scorePlansEntity.collegeLabel =
                item.scorePlansEntity.collegeLabel.replaceAll(",", '/');
            } else if (
              item.scorePlansEntity.collegeLabel.indexOf("、") != -1
            ) {
              item.scorePlansEntity.collegeLabel =
                item.scorePlansEntity.collegeLabel.replaceAll("、", '/');
            }
          }
        }
        item.isempty = true
        item.isShow = false
        item.volunteerNumberCopy = JSON.parse(JSON.stringify(item.volunteerNumber))
        item.changeRank = false
      })
      self.tableData = res.data.dataTable.rows
      self.loading = false
    },
    // 删除志愿
    del(item) {
      const self = this
      this.$confirm('此操作将删除该志愿, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.index == 0) {
            REQvolunteerDel({ autoId: item.autoId }).then(res => {
              if (res.code == 200) {
                if (self.tableData.length == 1 && self.queryParams.pageNum > 1) {
                  self.queryParams.pageNum = self.queryParams.pageNum - 1
                }
                self.onload()
                self.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              }
            })
          } else {
            REQvolunteerDelZN({ conId: item.conId }).then(res => {
              if (res.code == 200) {
                if (self.tableData.length == 1 && self.queryParams.pageNum > 1) {
                  self.queryParams.pageNum = self.queryParams.pageNum - 1
                }
                self.onload()
                self.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              }
            })
          }
        })
        .catch(() => {
          self.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 志愿换位
    handClick(item, index) {
      const self = this
      item.changeRank = true
      const obj = {
        userId: this.$store.state.userInfo.phonenumber,
        oldNumber: item.volunteerNumberCopy,
        newNumber: item.volunteerNumber,
        id: this.$route.query.id
      }
      if (this.index == 0) {
        REQvolunteercheckspace(obj).then(res => {
          if (res.code == 200) {
            self.$message({
              type: 'success',
              message: '换位成功'
            })
            self.$refs[`popoverZZ-${index}`].doClose()
            self.onload()
          }
        })
      } else {
        REQvolunteercheckspaceZN(obj).then(res => {
          if (res.code == 200) {
            self.$message({
              type: 'success',
              message: '换位成功'
            })
            self.$refs[`popoverZN-${index}`].doClose()
            self.onload()
          }
        })
      }
    },
    popoverHide(item, index) {
      if (!item.changeRank) {
        item.rank = item.rankCopy
      }
    }
  }
}
</script>

<style scoped lang="scss">
$mainColor: #30c9b2;

.voluntaryform {
  .voluntaryTitle {
    font-size: 12px;
    color: #666;
    line-height: 20px;
    width: 100%;
    border: 1px dashed #12B098;
    border-radius: 3px;
    background: #ebfaf8;
    padding: 10px;
    margin: 2% 0 20px 0;

    .iconToast {
      font-size: 14px;
      font-weight: 900;

      span {}
    }

    .columnToast {
      margin-top: 5px;
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 30px 10px 30px !important;

    h1 {
      font-weight: 800;
      font-size: 18px;
    }

    h2 {
      font-size: 16px;
      font-weight: 800;
      margin: 10px 0px;
    }

    div:nth-of-type(2) {
      color: $mainColor;
      width: 88px;
      text-align: center;
      height: 30px;
      line-height: 30px;
      border: 1px solid $mainColor;
      border-radius: 6px;
      cursor: pointer;
    }
  }

  ::v-deep .el-tabs--border-card>.el-tabs__content {
    padding: 0px !important;
  }

  .el-table span {
    display: inline-block;
    margin: 5px 0px;
  }



  .el-table .schoolNumber {
    background: #ccc;

    border-radius: 10px;
    padding: 0 5px;
    margin-right: 5px;
  }

  .el-table .schoolAttribute {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 3px;
    padding: 0 3px;
  }

  .el-table .schoolPlan {
    font-size: 15px;
    font-weight: 800;
    margin: 0px 0 10px 0px !important;
  }

  .el-table .grade {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white;
    text-align: center;
    /* margin-left: auto; */
    line-height: 30px;
    margin: 0 auto;
  }

  .el-table .schoolName {
    font-weight: 800;
    font-size: 15px;
    cursor: pointer;
  }

  .el-table .txagn {
    // text-align: left;
    // padding-left:15px;
  }

  .el-table .sbText {
    display: inline-block;
    font-size: 13px;
    margin: 0 5px;
    width: 20px;
    height: 20px;
    color: white;
    border-radius: 50%;

  }

  .el-table .f13 {
    font-size: 13px;
    color: #86909c;
  }

  .el-table .itemDiv {
    display: flex;
    position: absolute;
    background: white;
    left: -639px;
    height: 50px;
    z-index: 102;
    border-radius: 10px;
    border: 1px solid #ccc;

    div:hover {
      color: #30c9b2;
    }

    div {
      width: 73px;
      cursor: pointer;
      height: 50px;
      border-right: 1px solid #ccc;
      text-align: center;

      p:nth-of-type(1) {
        border-bottom: 1px solid #ccc;
      }
    }
  }

  ::v-deep .el-table .el-table__row {
    height: 135px;
  }

  .el-table .leadershipBtn {
    width: 80px;
    // height: 33px;
    background: #FFFFFF;
    border: 3px solid #12B098;
    border-radius: 17px;
    line-height: 30px;
    text-align: center;
    color: #12B098;
    cursor: pointer;
    margin-right: 20px;
  }
}
</style>
